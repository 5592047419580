const CATEGORY_ALL = "all";

const VIEWER_CATEGORIES = [
    {
        label: "All Categories",
        value: CATEGORY_ALL,
        checked: true
    },
    {
        label: "Whole Pages",
        value: "whole-pages",
        checked: true
    },
    {
        label: "Regions",
        value: "regions",
        checked: true
    },
    {
        label: "Story Arcs",
        value: "story-arcs",
        checked: true
    },
    {
        label: "Glyphs",
        value: "glyphs",
        checked: true
    },
    {
        label: "Glosses",
        value: "glosses",
        checked: true
    },
    {
        label: "FORS/XRF",
        value: "fors/xrf",
        checked: true
    },
    {
        label: "Other",
        value: "other",
        checked: true
    }
]

export { VIEWER_CATEGORIES, CATEGORY_ALL };