export default {
  de: {
    annotationAdd: 'Neue Annotation',
    annotationView: '',
    annotationBrowse: '',
    annotationBtn_cancel: 'Abbrechen',
    annotationBtn_create: 'Neue Annotation erstellen',
    annotationBtn_view: '',
    annotationBtn_delete: 'Annotation löschen',
    annotationBtn_edit: 'Annotation bearbeiten',
    annotationBtn_export: 'Exportiere sichtbare lokale Annotationen',
    annotationBtn_submit: 'Speichern',
    annotationCanvas_tools: 'Annotations-Werkzeuge',
    annotationEdit: 'Annotation bearbeiten',
    body: 'Kommentar',
    bodyBtn_confirm: 'Bestätigen',
    bodyBtn_delete: 'Löschen',
    bodyBtn_edit: 'Bearbeiten',
    color: 'Farbe',
    createBtn_body: 'Hinzufügen',
    createBtn_target: 'Hinzufügen',
    creator: 'Autor*in',
    dialog_annotationExport_annotation: 'Exportiere Annotationen für "{{ annotation }}"',
    dialog_annotationExport_noAnnotation: 'Keine exportierbaren Annotationen vorhanden.',
    dialog_annotationExport_title: 'Annotationen exportieren',
    dialog_annotationSubmit_content: 'Die Annotation enthält bisher keine Informationen zur Autor*in. Diese Angabe kann nachträglich nicht mehr geändert werden.',
    dialog_annotationSubmit_title: 'Annotation ohne Autor*in speichern?',
    dialog_annotationSubmitBtn_cancel: 'Abbrechen',
    dialog_annotationSubmitBtn_submit: 'Annotation speichern',
    dialog_singleCanvas_content: 'Annotationen können nur in der Einzelansicht bearbeitet werden. Jetzt zur Einzelansicht wechseln?',
    dialog_singleCanvas_title: 'Zur Einzelansicht wechseln?',
    dialog_singleCanvasBtn_cancel: 'Abbrechen',
    dialog_singleCanvasBtn_submit: 'Zur Einzelansicht wechseln',
    headerLabel_layers: '',
    headerLabel_category: '',
    headerLabel_annotations: '',
    headerLabel_additional_resources: '',
    headerLabel_body: 'Inhalt',
    headerLabel_describing: 'Beschreibung',
    headerLabel_metadata: 'Allgemein',
    headerLabel_tag: 'Tags',
    headerLabel_target: 'Bereich',
    metadata: 'Angabe',
    metadataBtn_confirm: 'Bestätigen',
    metadataBtn_edit: 'Bearbeiten',
    motivation: 'Motivation',
    target: 'Zone',
    targetBtn_confirm: 'Bestätigen',
    targetBtn_delete: 'Löschen',
    targetBtn_edit: 'Bearbeiten',
    targetToolos_color: 'Wähle Farbe {{ color }}',
    targetTools_ellipse: 'Wähle Kreis',
    targetTools_freehand: 'Wähle Freihand',
    targetTools_rectangle: 'Wähle Rechteck',
    targetTools_selection: 'Zonenauswahl Werkzeuge',
    textEditor_format_bold: 'Fett',
    textEditor_format_italic: 'Kursiv',
    textEditor_format_selection: 'Texteditor Formatierung',
    value_default: '[keine Angabe]',
  },
  en: {
    annotationAdd: 'New annotation',
    annotationView: 'View annotation',
    annotationBrowse: 'Browse annotations',
    annotationBtn_cancel: 'Cancel',
    annotationBtn_create: 'Create new annotation',
    annotationBtn_view: 'View annotations',
    annotationBtn_delete: 'Delete annotation',
    annotationBtn_edit: 'Edit annotation',
    annotationBtn_export: 'Export visible local annotations',
    annotationBtn_submit: 'Save',
    annotationCanvas_tools: 'Annotation tools',
    annotationEdit: 'Edit annotation',
    body: 'Comment',
    bodyBtn_confirm: 'Confirm',
    bodyBtn_delete: 'Delete',
    bodyBtn_edit: 'Edit',
    color: 'Color',
    createBtn_body: 'Add',
    createBtn_target: 'Add',
    creator: 'Creator',
    dialog_annotationExport_annotation: 'Export annotations for "{{ annotation }}"',
    dialog_annotationExport_noAnnotation: 'No exportable annotations available.',
    dialog_annotationExport_title: 'Export annotations',
    dialog_annotationSubmit_content: 'This annotation does not yet contain information about its creator. This cannot be changed later.',
    dialog_annotationSubmit_title: 'Submit annotation?',
    dialog_annotationSubmitBtn_cancel: 'Cancel',
    dialog_annotationSubmitBtn_submit: 'Submit annotation',
    dialog_singleCanvas_content: 'Annotations can only be edited in single canvas view type. Switch to single view now?',
    dialog_singleCanvas_title: 'Switch view type to single view?',
    dialog_singleCanvasBtn_cancel: 'Cancel',
    dialog_singleCanvasBtn_submit: 'Switch to single view',
    headerLabel_layers: 'Layers',
    headerLabel_category: 'Category',
    headerLabel_annotations: 'Annotations',
    headerLabel_additional_resources: 'Additional Resources',
    headerLabel_body: 'Body',
    headerLabel_describing: 'Description',
    headerLabel_metadata: 'Metadata',
    headerLabel_tag: 'Tags',
    headerLabel_target: 'Target',
    headerLabel_linking: 'Additional Resources',
    metadata: 'Metadata',
    metadataBtn_confirm: 'Confirm',
    metadataBtn_edit: 'Edit',
    motivation: 'Motivation',
    target: 'Target',
    targetBtn_confirm: 'Confirm',
    targetBtn_delete: 'Delete',
    targetBtn_edit: 'Edit',
    targetToolos_color: 'Select color {{ color }}',
    targetTools_ellipse: 'Select circle',
    targetTools_freehand: 'Select freehand',
    targetTools_rectangle: 'Select rectangle',
    targetTools_selection: 'Target svg tool selection',
    textEditor_format_bold: 'Bold',
    textEditor_format_italic: 'Italic',
    textEditor_format_selection: 'Text editor format selection',
    value_default: '[Input value]',
  },
};
