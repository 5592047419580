import flatten from 'lodash/flatten';

/** */
export function mapChildren(layerThing) {
  if (layerThing.children) {
    return flatten(layerThing.children.map((child) => mapChildren(child)));
  }
  return layerThing;
}

export function getMonthString(mth) {
  let month = "";

  switch (mth) {
    case 1:
      month = "January";
      break;
    case 2:
      month = "February";
      break;
    case 3:
      month = "March";
      break;
    case 4:
      month = "April";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "June";
      break;
    case 7:
      month = "July";
      break;
    case 8:
      month = "August";
      break;
    case 9:
      month = "September";
      break;
    case 10:
      month = "October";
      break;
    case 11:
      month = "November";
      break;
    default:
      month = "December";
      break;
  }

  return month;
}

export function linkify(inputText) {
  const regex = /(\b(https?:\/\/)[\S]+)/gi;
  return inputText.replace(regex, '<a href="$1" target="_blank">$1</a>');
}